import { createSelector } from 'reselect';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { addBusinessDays } from 'swag-common/utils/add-business-days.util';
import { isSameOfAfter } from 'swag-common/utils/date-lib';
import { CHANGE_MOCKUP_REQUEST_ADDITIONAL_DAYS } from 'swag-common/constants/main-swag.constants';
import { STATE_LOADING } from '../redux.constants';
import { STEPS } from './mockup-manager.constants';
const rootSelector = state => state.mockupManager;
export const currentStepSelector = createSelector(rootSelector, ({
  isUserReadBeforeApproving,
  isNotecardReview,
  isLogoUploadCompleted,
  isRequestingChanges
}) => {
  if (!isUserReadBeforeApproving) {
    return STEPS.BEFORE_APPROVING_MOCKUP;
  }
  if (!isRequestingChanges || !isNotecardReview) {
    return STEPS.APPROVE_MOCKUP;
  }
  if (!isLogoUploadCompleted) {
    return STEPS.NOTECARD_LOGO_AND_COLOR;
  }
  return STEPS.NOTECARD_TEXT_CONTENT;
});
export const mockupSelector = createSelector(rootSelector, mockupManager => mockupManager.mockup);
export const isNotecardReviewSelector = createSelector(rootSelector, mockupManager => mockupManager.isNotecardReview);
export const isMockupAvailableSelector = createSelector(mockupSelector, mockup => {
  return Boolean(mockup);
});
export const itemIdSelector = createSelector(rootSelector, mockupManager => mockupManager.itemId);
export const orderIdSelector = createSelector(rootSelector, mockupManager => mockupManager.orderId);
export const requestChangesStateSelector = createSelector(rootSelector, mockupManager => mockupManager.requestChangesState);
export const stateSelector = createSelector(rootSelector, mockupManager => mockupManager.state);
export const isDisabledSelector = createSelector(rootSelector, mockupManager => mockupManager.isDisabled);
export const isRequestingChangesSelector = createSelector(rootSelector, mockupManager => mockupManager.isRequestingChanges);
export const isPdfSelector = createSelector(rootSelector, mockupManager => {
  const fileNameParts = mockupManager.mockup.fileName.split('.');
  return fileNameParts[fileNameParts.length - 1] === 'pdf';
});
export const changeRequestTextSelector = createSelector(rootSelector, mockupManager => mockupManager.mockup.changeRequest);
export const isNotecardChangesSubmitting = createSelector(requestChangesStateSelector, state => state === STATE_LOADING);
export const shouldUpdateInHandDateSelector = state => {
  const {
    orderId,
    itemId
  } = rootSelector(state);
  const {
    accountOrders,
    companyOrders
  } = state;
  const {
    items
  } = [...accountOrders.orders, ...companyOrders.orders].find(({
    _id
  }) => _id === orderId);
  const {
    expectedByCustomerDates,
    boxItemsId,
    isPrintedSample
  } = items.find(({
    _id
  }) => _id === itemId);
  if (isPrintedSample) {
    return false;
  }
  if (boxItemsId) {
    var _boxItem$expectedByCu, _boxItem$expectedByCu2;
    const newInHandDate = addBusinessDays({
      date: new Date(expectedByCustomerDates === null || expectedByCustomerDates === void 0 ? void 0 : expectedByCustomerDates.inHandDate),
      daysToAdd: CHANGE_MOCKUP_REQUEST_ADDITIONAL_DAYS,
      cutOffTime: false
    }).valueOf();
    const boxItem = items.find(item => isBox(item));
    const shouldUpdateBoxInHandDate = (boxItem === null || boxItem === void 0 ? void 0 : (_boxItem$expectedByCu = boxItem.expectedByCustomerDates) === null || _boxItem$expectedByCu === void 0 ? void 0 : _boxItem$expectedByCu.inHandDate) && isSameOfAfter(new Date(newInHandDate), new Date(boxItem === null || boxItem === void 0 ? void 0 : (_boxItem$expectedByCu2 = boxItem.expectedByCustomerDates) === null || _boxItem$expectedByCu2 === void 0 ? void 0 : _boxItem$expectedByCu2.inHandDate));
    return shouldUpdateBoxInHandDate;
  } else {
    return true;
  }
};