function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import getUniqueSizeSet from 'swag-common/business-logic/products/get-unique-size-set.logic';
import { SPECIAL_PRODUCT_BUNDLE_ADDON_TYPES, SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import { isBoxType } from './product-is-box-type.util';
export function isBundle(product) {
  var _product$bundleConten;
  return Boolean((product === null || product === void 0 ? void 0 : (_product$bundleConten = product.bundleContent) === null || _product$bundleConten === void 0 ? void 0 : _product$bundleConten.length) > 0);
}
export function getBundleVariants(product) {
  const bundleVariants = (product === null || product === void 0 ? void 0 : product.bundleContent) || [];
  return bundleVariants === null || bundleVariants === void 0 ? void 0 : bundleVariants.reduce((acc, {
    product
  }) => product ? [...acc, product] : acc, []);
}
export function isBundleItem(item) {
  return Boolean(item === null || item === void 0 ? void 0 : item.bundleCustomProductId);
}
export function isInBundleItem(item) {
  return Boolean(item === null || item === void 0 ? void 0 : item.bundleCustomProductId) && !isBundleBox(item);
}
export function isBundleBox(item) {
  var _item$prod;
  return Boolean(item === null || item === void 0 ? void 0 : item.bundleCustomProductId) && (item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.designUploadMode) === DesignUploadMode.box;
}
export function isBundleAddon(swagProduct, variants = []) {
  return !!(swagProduct !== null && swagProduct !== void 0 && swagProduct.specialProductType && SPECIAL_PRODUCT_BUNDLE_ADDON_TYPES.includes(swagProduct.specialProductType) && variants.every(v => {
    var _v$boxContent;
    return !(v !== null && v !== void 0 && (_v$boxContent = v.boxContent) !== null && _v$boxContent !== void 0 && _v$boxContent.length);
  }));
}
export function isBundleNotecard(swagProductId, variants = []) {
  return isBundleAddon(swagProductId, variants) && (swagProductId === null || swagProductId === void 0 ? void 0 : swagProductId.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
}
export function findNotecardInBundleContent(bundleContent) {
  return bundleContent === null || bundleContent === void 0 ? void 0 : bundleContent.find(item => {
    var _item$swagProductId;
    return (item === null || item === void 0 ? void 0 : (_item$swagProductId = item.swagProductId) === null || _item$swagProductId === void 0 ? void 0 : _item$swagProductId.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
  });
}
export function hasNotecardInBundle(bundleContent) {
  return Boolean(findNotecardInBundleContent(bundleContent));
}
export function findPackagingInBundleContent(bundleContent) {
  return bundleContent === null || bundleContent === void 0 ? void 0 : bundleContent.find(item => isBoxType(item === null || item === void 0 ? void 0 : item.swagProductId));
}
export function hasPackagingInBundle(bundleContent) {
  return Boolean(findPackagingInBundleContent(bundleContent));
}
export function isBundleWithSizes(product) {
  var _product$bundleConten2;
  return Boolean(product === null || product === void 0 ? void 0 : (_product$bundleConten2 = product.bundleContent) === null || _product$bundleConten2 === void 0 ? void 0 : _product$bundleConten2.some(item => (item === null || item === void 0 ? void 0 : item.sizeSettings) && (item === null || item === void 0 ? void 0 : item.sizeSettings.options.length) > 1));
}
export function getBundleSizeSet(bundle) {
  var _bundle$variants$, _bundle$variants$$bun;
  if (!bundle) {
    return {};
  }
  const bundleProducts = (_bundle$variants$ = bundle.variants[0]) === null || _bundle$variants$ === void 0 ? void 0 : (_bundle$variants$$bun = _bundle$variants$.bundleContent) === null || _bundle$variants$$bun === void 0 ? void 0 : _bundle$variants$$bun.map(({
    product: {
      swagProductId,
      sizeSettings
    }
  }) => {
    var _sizeSettings$options;
    return _objectSpread({}, swagProductId, {
      hasSize: (swagProductId === null || swagProductId === void 0 ? void 0 : swagProductId.hasSize) || Boolean((sizeSettings === null || sizeSettings === void 0 ? void 0 : (_sizeSettings$options = sizeSettings.options) === null || _sizeSettings$options === void 0 ? void 0 : _sizeSettings$options.length) > 1),
      sizeId: sizeSettings && sizeSettings._id,
      productSize: sizeSettings
    });
  });
  return getUniqueSizeSet(bundleProducts || []);
}
export function filterBundleContentApparel(bundleContent) {
  var _bundleContent$sizeSe;
  return bundleContent.sizeSettings && ((_bundleContent$sizeSe = bundleContent.sizeSettings.options) === null || _bundleContent$sizeSe === void 0 ? void 0 : _bundleContent$sizeSe.length) > 1;
}
export function getSizeSettingsFromBundleContent(bundleContent) {
  const apparelBundleContent = bundleContent.filter(filterBundleContentApparel);
  return (apparelBundleContent || []).reduce((acc, content) => {
    var _content$sizeSettings, _acc$options;
    if (!acc && content.sizeSettings) {
      return content.sizeSettings;
    }
    if (acc && content.sizeSettings && ((_content$sizeSettings = content.sizeSettings.options) === null || _content$sizeSettings === void 0 ? void 0 : _content$sizeSettings.length) > ((_acc$options = acc.options) === null || _acc$options === void 0 ? void 0 : _acc$options.length)) {
      return content.sizeSettings;
    }
    return acc;
  }, null);
}
export function groupBundleContentWithSizesByColor(acc, content) {
  var _content$product, _content$product2;
  const key = `${(_content$product = content.product) === null || _content$product === void 0 ? void 0 : _content$product.parentId}-${(_content$product2 = content.product) === null || _content$product2 === void 0 ? void 0 : _content$product2.colorName}`;
  if (!content.product || content.sizeSettings === null) {
    return acc;
  }
  if (Object.prototype.hasOwnProperty.call(acc, key)) {
    acc[key].push(content);
  } else {
    acc[key] = [content];
  }
  return acc;
}
export function getProductsFromGroupedContent(content) {
  return content.map(content => content.product);
}
function preventNegativeQuantity(quantity) {
  return Math.max(quantity, 0);
}
export function bundleInStockCalculationLogic({
  inStockQuantity,
  inReserveStockQuantity,
  quantityPerBundle
}) {
  return preventNegativeQuantity(Math.floor((inStockQuantity - inReserveStockQuantity) / quantityPerBundle));
}
export function bundleInProductionCalculationLogic(inStockValue) {
  return ({
    inStockQuantity,
    inProductionQuantity,
    inReserveStockQuantity,
    inReserveProductionQuantity,
    quantityPerBundle
  }) => preventNegativeQuantity(Math.floor((inStockQuantity + inProductionQuantity - inReserveStockQuantity - inReserveProductionQuantity) / quantityPerBundle) - inStockValue);
}
export function getBundleQuantitiesForApparelContent(bundleContent) {
  const bundleContentApparelsOnly = bundleContent.filter(filterBundleContentApparel);
  const groupedApparelsByColor = bundleContentApparelsOnly.reduce(groupBundleContentWithSizesByColor, {});
  const smallestSizeSettings = getSizeSettingsFromBundleContent(bundleContentApparelsOnly);
  return ((smallestSizeSettings === null || smallestSizeSettings === void 0 ? void 0 : smallestSizeSettings.options) || []).map(sizeName => {
    const neededVariants = Object.values(groupedApparelsByColor || {}).map(getProductsFromGroupedContent).flat().filter(v => {
      var _v$sizeSettings;
      return ((_v$sizeSettings = v.sizeSettings) === null || _v$sizeSettings === void 0 ? void 0 : _v$sizeSettings.options[v.sizeIndex || 0]) === sizeName;
    });
    const isEveryProductHasThisSize = neededVariants.length === Object.keys(groupedApparelsByColor || {}).length;
    if (!isEveryProductHasThisSize) {
      return {
        size: sizeName,
        quantity: 0,
        inReserveQuantity: 0,
        inProductionQuantity: 0
      };
    }
    const inStockQuantity = Math.min(...neededVariants.map(bundleInStockCalculationLogic));
    const inProductionQuantity = Math.min(...neededVariants.map(bundleInProductionCalculationLogic(inStockQuantity)));
    return {
      size: sizeName,
      quantity: inStockQuantity,
      quantityAvailable: inStockQuantity + inProductionQuantity,
      inReserveQuantity: 0,
      inProductionQuantity: inProductionQuantity
    };
  });
}
export function reduceVariantFromProducts(variantId) {
  return (acc, product) => {
    if (acc) {
      return acc;
    }
    const variant = product.variants.find(variant => String(variant._id) === variantId);
    if (variant) {
      return variant;
    }
    return acc;
  };
}
export const isDefaultBundleImage = productDefaultImage => {
  return productDefaultImage === null || productDefaultImage === void 0 ? void 0 : productDefaultImage.includes('bundle');
};