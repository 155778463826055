import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { editMockupChangeRequest, setIsLogoUploadCompleted, submitReviewedNotecard } from '../../../../../redux/mockup-manager/mockup-manager.actions';
import { notecardColorSelector, notecardLogoPreviewSelector, notecardTextContentSelector } from '../../../../../redux/notecard-editor/notecard-editor.selectors';
import { updateNotecardTextContent } from '../../../../../redux/notecard-editor/notecard-editor.actions';
import { changeRequestTextSelector, isNotecardChangesSubmitting, shouldUpdateInHandDateSelector } from '../../../../../redux/mockup-manager/mockup-manages.selectors';
import { TextEditorStepComponent } from '../../../edit-box-notecard-popup/components/text-editor-step/text-editor-step';
const mapStateToProps = createStructuredSelector({
  color: notecardColorSelector,
  logo: notecardLogoPreviewSelector,
  textContent: notecardTextContentSelector,
  feedbackText: changeRequestTextSelector,
  isLoading: isNotecardChangesSubmitting,
  isRevision: () => true,
  shouldUpdateInHandDate: shouldUpdateInHandDateSelector
});
const mapDispatchToProps = dispatch => ({
  onNext: mockupContainer => {
    dispatch(submitReviewedNotecard(mockupContainer));
  },
  onBack: () => {
    dispatch(setIsLogoUploadCompleted(false));
  },
  handleNotecardTextContentChange: html => {
    dispatch(updateNotecardTextContent(html));
  },
  onEditFeedback: text => {
    dispatch(editMockupChangeRequest(text));
  }
});
export const TextEditorStep = connect(mapStateToProps, mapDispatchToProps)(TextEditorStepComponent);