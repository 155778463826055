import { defaultProdTime, getBoxProductionTimeList, getBundleProductionTimeList } from '../get-box-production-time-list.util';
import { isBox } from '../order/item-is-box.util';
export const getProductionTimeListForItem = (item, isCart, isCheckout, draftItems, items) => {
  var _item$prod$production, _item$prod;
  if (isBox(item)) {
    const correctItems = isCart || isCheckout ? items : draftItems;
    return getBoxProductionTimeList(item, correctItems);
  }
  if (item.bundleCustomProductId) {
    return getBundleProductionTimeList(item, items);
  }
  return (_item$prod$production = item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.productionTimeList) !== null && _item$prod$production !== void 0 ? _item$prod$production : [defaultProdTime];
};