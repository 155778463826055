import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { requestChanges, setIsLogoUploadCompleted } from '../../../../../redux/mockup-manager/mockup-manager.actions';
import { shouldUpdateInHandDateSelector } from '../../../../../redux/mockup-manager/mockup-manages.selectors';
import { isNotecardLoadingSelector, notecardColorSelector, notecardLogoPreviewSelector } from '../../../../../redux/notecard-editor/notecard-editor.selectors';
import { changeNotecardColor, deleteNotecardLogo, uploadNotecardLogo } from '../../../../../redux/notecard-editor/notecard-editor.actions';
import { LogoUploadStepComponent } from '../../../edit-box-notecard-popup/components/logo-upload-step/logo-upload-step';
const mapStateToProps = createStructuredSelector({
  color: notecardColorSelector,
  logo: notecardLogoPreviewSelector,
  isLogoLoading: isNotecardLoadingSelector,
  isRevision: () => true,
  shouldUpdateInHandDate: shouldUpdateInHandDateSelector
});
const mapDispatchToProps = dispatch => ({
  onNext: () => {
    dispatch(setIsLogoUploadCompleted(true));
  },
  onBack: () => {
    dispatch(requestChanges(false));
  },
  onColorChange: color => {
    dispatch(changeNotecardColor(color));
  },
  onLogoUpload: file => {
    dispatch(uploadNotecardLogo(file));
  },
  onLogoDelete: () => {
    dispatch(deleteNotecardLogo());
  }
});
export const LogoUploadStep = connect(mapStateToProps, mapDispatchToProps)(LogoUploadStepComponent);